<template>
  <div
    class="art-wrapper"
    :class="{ 'rounded-top': roundedTop }"
  >
    <div>
      <slot />
    </div>
  </div>
</template>

<script>
import { ensureBox, createUseElement, SVGNS } from '@/utils/svgUtils'
import { simpleHash } from '@/utils/utils'

export default {
  props: {
    seed: { default: 2, type: Number },
    text: { default: '', type: String },
    type: { default: 'profile', type: String },
    above: { default: false, type: Boolean },
    roundedTop: { default: false, type: Boolean },
  },
  computed: {
    initials () {
      if (!this.text) return
      let val = this.text
      const hasLetters = /\p{Letter}\p{Mark}?/u.test(val)
      if (hasLetters) {
        // If we have *any* normal letters, strip out anything else (e.g. emojis)
        // This means if we have only emojis/non-letters, we still use those for the initials
        val = val.replaceAll(/[^\p{Letter}\p{Mark} ]/gu, '')
      }
      let parts = val.split(' ').filter(Boolean).map(s => Array.from(s)[0])
      if (parts.length === 0) {
        return '?'
      }
      else if (parts.length > 2) {
        parts = [parts[0], parts[parts.length - 1]]
      }
      return parts.join('').toUpperCase()
    },
    box () {
      const id = simpleHash([
        this.seed,
        this.text,
        this.type,
        this.above,
        this.initials,
      ].join(':'))

      const svg = document.createElementNS(SVGNS, 'svg')

      svg.setAttribute('xmlns:xlink', 'http://www.w3.org/1999/xlink')

      if (this.above) {
        svg.setAttribute('viewBox', '0 -40 100 40')
        svg.setAttribute('class', 'random-art-box-above')
      }
      else {
        svg.setAttribute('viewBox', '0 0 100 100')
      }

      ensureBox(id, this)

      svg.appendChild(createUseElement(id))

      const wrapper = document.createElement('div')
      wrapper.setAttribute('style', 'height: 100%; width: 100%; overflow: hidden; position: absolute; top: 0; left: 0; z-index: -1;')
      wrapper.appendChild(svg)

      return wrapper
    },
  },
  watch: {
    box (box, prevBox) {
      this.$el.replaceChild(box, prevBox)
    },
  },
  mounted () {
    this.$el.appendChild(this.box)
  },
}
</script>

<style scoped lang='sass'>
.art-wrapper
  position: relative
  z-index: 0
  display: block
  width: 100%
  margin: 0
  user-select: none
  overflow: hidden
  &.rounded-top
    border-top-left-radius: 12px
    border-top-right-radius: 12px

  > div
    z-index: 1
    min-height: calc(25px + 1vw)

body.mobile .art-wrapper
  overflow: hidden
  border: 0
</style>

<style lang='sass'>
.random-art-box-above
  position: absolute
  bottom: 0
</style>
