import { useInfiniteQuery, useQuery, useQueryClient } from '@tanstack/vue-query'
import { computed, unref } from 'vue'

import api from '@/agreements/api/agreements'
import { infiniteScroll } from '@/messages/queries'
import { useSocketEvents } from '@/utils/composables'
import { extractCursor, flattenPaginatedData } from '@/utils/queryHelpers'

export const QUERY_KEY_BASE = 'agreements'
export const queryKeyAgreementList = params => [QUERY_KEY_BASE, 'list', params].filter(Boolean)
export const queryKeyAgreementItem = agreementId => [QUERY_KEY_BASE, 'item', agreementId].filter(Boolean)

/**
 * Handler for socket updates
 */
export function useAgreementsUpdater () {
  const queryClient = useQueryClient()
  const { on } = useSocketEvents()
  on('agreements:agreement', async () => {
    // TODO: slighty more optimal one would be directly update matching item query
    await queryClient.invalidateQueries({ queryKey: [QUERY_KEY_BASE] })
  })
}

export function useAgreementListQuery ({
  groupId,
  active,
  reviewDue,
  pageSize = 10,
}, queryOptions = {}) {
  const query = useInfiniteQuery({
    queryKey: queryKeyAgreementList({ groupId, active, reviewDue }),
    initialPageParam: null,
    queryFn: ({ pageParam }) => api.list({
      group: unref(groupId),
      active: unref(active),
      reviewDue: unref(reviewDue),
      cursor: pageParam,
      pageSize,
    }),
    gcTime: 1000,
    staleTime: 1000,
    enabled: computed(() => Boolean(unref(groupId))),
    getNextPageParam: page => extractCursor(page.next) || undefined,
    select: ({ pages, pageParams }) => ({
      pages: pages.map(page => page.results),
      pageParams,
    }),
    ...queryOptions,
  })

  return {
    ...query,
    infiniteScroll: infiniteScroll(query),
    agreements: flattenPaginatedData(query),
  }
}

export function useAgreementItemQuery ({ agreementId }, queryOptions = {}) {
  const query = useQuery({
    queryKey: queryKeyAgreementItem(agreementId),
    queryFn: () => api.get(unref(agreementId)),
    enabled: computed(() => Boolean(unref(agreementId))),
    ...queryOptions,
  })
  return {
    ...query,
    agreement: query.data,
  }
}
