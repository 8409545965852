import { useInfiniteQuery, useQuery, useQueryClient } from '@tanstack/vue-query'
import { computed, unref } from 'vue'

import api from '@/issues/api/issues'
import { infiniteScroll } from '@/messages/queries'
import { useSocketEvents } from '@/utils/composables'
import { extractCursor, flattenPaginatedData, useQueryHelpers } from '@/utils/queryHelpers'

export const QUERY_KEY_BASE = 'issues'
export const queryKeyIssueList = params => [QUERY_KEY_BASE, 'list', params].filter(Boolean)
export const queryKeyIssueDetail = issueId => [QUERY_KEY_BASE, 'detail', issueId].filter(Boolean)

export function useIssuesUpdater () {
  const queryClient = useQueryClient()
  const { on } = useSocketEvents()
  const { maybeUpdateDataWith } = useQueryHelpers()
  on('issues:issue', updatedIssue => {
    queryClient.setQueryData(
      queryKeyIssueDetail(updatedIssue.id),
      maybeUpdateDataWith(updatedIssue),
    )

    let updated = false
    queryClient.setQueriesData(
      { queryKey: queryKeyIssueList() },
      maybeUpdateDataWith(updatedIssue, () => {
        updated = true
      }),
    )
    if (!updated) {
      queryClient.invalidateQueries({ queryKey: queryKeyIssueList() })
    }
  })
}

export function useIssueListQuery ({ groupId, status }) {
  const query = useInfiniteQuery({
    queryKey: queryKeyIssueList({ groupId, status }),
    initialPageParam: null,
    queryFn: ({ pageParam }) => api.list({
      group: unref(groupId),
      status: unref(status),
      cursor: pageParam,
    }),
    enabled: computed(() => Boolean(unref(groupId))), // group is required
    // TODO: explore these more
    gcTime: 1000,
    staleTime: 0,
    getNextPageParam: page => extractCursor(page.next) || undefined,
    select: ({ pages, pageParams }) => ({
      pages: pages.map(page => page.results),
      pageParams,
    }),
  })
  return {
    ...query,
    infiniteScroll: infiniteScroll(query),
    issues: flattenPaginatedData(query),
  }
}

export function useIssueDetailQuery ({ issueId }) {
  const query = useQuery({
    queryKey: queryKeyIssueDetail(issueId),
    queryFn: () => api.get(unref(issueId)),
    enabled: computed(() => Boolean(unref(issueId))),
  })
  return {
    ...query,
    issue: query.data,
  }
}
