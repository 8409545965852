<template>
  <!-- eslint-disable vue/no-v-html -->
  <div
    v-bind="$attrs"
    class="markdown"
    v-html="rendered"
  />
  <!-- eslint-enable -->
</template>

<script>
import { useUserService } from '@/users/services'

import markdown from './markdownRenderer'

export default {
  props: {
    source: {
      required: true,
      type: String,
    },
    mentions: {
      default: false,
      type: Boolean,
    },
  },
  setup () {
    const { usersByUsername } = useUserService()
    return { usersByUsername }
  },
  computed: {
    markdownEnv () {
      if (this.mentions && this.usersByUsername) {
        return {
          usersByUsername: this.usersByUsername,
        }
      }
      return {}
    },
    rendered () {
      return markdown.render(this.source, this.markdownEnv)
    },
  },
}
</script>

<style scoped lang="sass">
@use '@/css/content'

.markdown
  overflow-wrap: break-word

.markdown ::v-deep()
  @include content.formatting
</style>
