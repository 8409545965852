import twemoji from '@twemoji/api'
import emojiList from 'markdown-it-emoji/lib/data/full.mjs'

const EMOJI_CACHE = {}
// TODO: once https://codeberg.org/karrot/karrot-frontend/pulls/2747 is merged combine the Emoji element with this somehow
export function getEmojiElement (name) {
  const cached = EMOJI_CACHE[name]
  if (cached) return EMOJI_CACHE[name].cloneNode(true)
  const container = document.createElement('div')
  container.innerHTML = twemoji.parse(emojiList[name])
  const el = container.firstChild
  EMOJI_CACHE[name] = el
  return el
}
