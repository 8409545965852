import { computed } from 'vue'

import { useSettingsQuery } from '@/base/queries'

export function useSettingsValue (key, defaultValue) {
  const { settings } = useSettingsQuery()
  return computed(() => getIn(key, settings.value) ?? defaultValue)
}

export function getIn (path, obj) {
  if (typeof path === 'string') {
    path = path.split('.')
  }
  return path.reduce((o, key) => o?.[key], obj)
}

function isObject (x) {
  return typeof x === 'object' &&
    x !== null &&
    !Array.isArray(x) &&
    !(x instanceof Function) &&
    !(x instanceof RegExp) &&
    !(x instanceof Error) &&
    !(x instanceof Date)
}

export function setIn (path, obj, value) {
  const originalObj = obj
  if (typeof path === 'string') {
    path = path.split('.')
  }
  const lastSegment = path[path.length - 1]
  for (const segment of path.slice(0, -1)) {
    if (segment in obj && isObject(obj[segment])) {
      obj = obj[segment]
    }
    else {
      obj = obj[segment] = {}
    }
  }
  if (value === null || typeof value === 'undefined') {
    delete obj[lastSegment]
  }
  else {
    obj[lastSegment] = value
  }
  removeEmptyObjects(originalObj)
  return originalObj
}

function isEmptyObject (obj) {
  return isObject(obj) && Object.keys(obj).length === 0
}

function removeEmptyObjects (obj) {
  for (const key of Object.keys(obj)) {
    if (isObject(obj[key])) {
      removeEmptyObjects(obj[key])
    }
    if (isEmptyObject(obj[key])) {
      delete obj[key]
    }
  }
}

export function unsetIn (path, obj) {
  const originalObj = obj
  if (typeof path === 'string') {
    path = path.split('.')
  }
  const lastSegment = path[path.length - 1]
  for (const segment of path.slice(0, -1)) {
    if (isObject(obj[segment])) {
      obj = obj[segment]
    }
    else {
      return // nothing to do!
    }
  }
  delete obj[lastSegment]
  removeEmptyObjects(originalObj)
}
