import axios, { parseCursor } from '@/base/api/axios'
import { underscorizeKeys } from '@/utils/utils'

export default {
  async list (params = {}) {
    const response = (await axios.get('/api/polls/', { params: underscorizeKeys(params) })).data
    return {
      ...response,
      next: parseCursor(response.next),
      results: convert(response.results),
    }
  },

  async get (pollId) {
    return convert((await axios.get(`/api/polls/${pollId}/`)).data)
  },

  async vote (data) {
    return (await axios.post('/api/poll-votes/', data)).data
  },

  async deleteVote (voteId) {
    return (await axios.delete(`/api/poll-votes/${voteId}/`)).data
  },
}

export function convert (val) {
  if (Array.isArray(val)) {
    return val.map(convert)
  }
  else {
    const result = { ...val }

    const dateFields = [
      'createdAt',
    ]

    for (const field of dateFields) {
      if (val[field]) {
        result[field] = new Date(val[field])
      }
    }

    return result
  }
}
