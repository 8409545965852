import { useInfiniteQuery, useQuery, useQueryClient } from '@tanstack/vue-query'
import { computed, unref } from 'vue'

import { infiniteScroll } from '@/messages/queries'
import api, { convert } from '@/polls/api/polls'
import { useSocketEvents } from '@/utils/composables'
import { extractCursor, flattenPaginatedData, useQueryHelpers } from '@/utils/queryHelpers'

export const QUERY_KEY_BASE = 'polls'
export const queryKeyPollList = params => [QUERY_KEY_BASE, 'list', params].filter(Boolean)
export const queryKeyPollItem = pollId => [QUERY_KEY_BASE, 'item', pollId].filter(Boolean)

/**
 * Handler for socket updates
 */
export function usePollsUpdater () {
  const queryClient = useQueryClient()
  const { on } = useSocketEvents()
  const { maybeUpdateDataWith } = useQueryHelpers()
  on('polls:poll', async poll => {
    poll = convert(poll)
    queryClient.setQueryData(
      queryKeyPollItem(poll.id),
      maybeUpdateDataWith(poll),
    )
    queryClient.setQueriesData(
      { queryKey: queryKeyPollList() },
      maybeUpdateDataWith(poll),
    )
  })
}

export function usePollListQuery ({
  groupId,
  voted,
  source,
  pageSize = 10,
}, queryOptions = {}) {
  const query = useInfiniteQuery({
    queryKey: queryKeyPollList({ groupId, voted, source }),
    initialPageParam: null,
    queryFn: ({ pageParam }) => api.list({
      group: unref(groupId),
      voted: unref(voted),
      source: unref(source),
      cursor: pageParam,
      pageSize,
    }),
    gcTime: 1000,
    staleTime: 1000,
    enabled: computed(() => Boolean(unref(groupId))),
    getNextPageParam: page => extractCursor(page.next) || undefined,
    select: ({ pages, pageParams }) => ({
      pages: pages.map(page => page.results),
      pageParams,
    }),
    ...queryOptions,
  })

  return {
    ...query,
    infiniteScroll: infiniteScroll(query),
    polls: flattenPaginatedData(query),
  }
}

export function usePollItemQuery ({ pollId }, queryOptions = {}) {
  const query = useQuery({
    queryKey: queryKeyPollItem(pollId),
    queryFn: () => api.get(unref(pollId)),
    enabled: computed(() => Boolean(unref(pollId))),
    ...queryOptions,
  })
  return {
    ...query,
    poll: query.data,
  }
}
