// add markup before and after selection
export function addMarkup (text, markup, selectionStart, selectionEnd) {
  const beforeText = text.substring(0, selectionStart)
  const selection = text.substring(selectionStart, selectionEnd)
  const afterText = text.substring(selectionEnd, text.length)

  return [beforeText, markup, selection, markup, afterText].join('')
}

// remove markup before and after selection
// assumes markup is present
export function removeMarkup (text, markup, selectionStart, selectionEnd) {
  const beforeText = text.substring(0, selectionStart - markup.length)
  const selection = text.substring(selectionStart, selectionEnd)
  const afterText = text.substring(selectionEnd + markup.length, text.length)

  return [beforeText, selection, afterText].join('')
}

// returns true when markup exists before and after selection
export function hasMarkup (text, markup, selectionStart, selectionEnd) {
  const beforeMarkup = text.substring(selectionStart - markup.length, selectionStart)
  const afterMarkup = text.substring(selectionEnd, selectionEnd + markup.length)

  return beforeMarkup === markup && afterMarkup === markup
}
