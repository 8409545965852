import { useQuery, useQueryClient } from '@tanstack/vue-query'

import api from '@/base/api/settings'
import { useSocketEvents } from '@/utils/composables'
import { useWait } from '@/utils/queryHelpers'

export const queryKeySettings = () => ['settings']

export function useSettingsUpdater () {
  const queryClient = useQueryClient()
  const { on } = useSocketEvents()
  on(
    'settings:updated',
    data => {
      queryClient.setQueryData(queryKeySettings(), data)
    },
  )
}

export function useSettingsQuery () {
  const query = useQuery({
    queryKey: queryKeySettings(),
    queryFn: () => api.fetch(),
    staleTime: Infinity,
  })
  return {
    ...query,
    wait: useWait(query),
    settings: query.data,
  }
}
