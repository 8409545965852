<template>
  <div
    ref="emojiRef"
    class="emoji"
  />
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue'

import { getEmojiElement } from '@/documents/emoji-element'

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
})

const emojiElement = computed(() => getEmojiElement(props.name))

const emojiRef = ref(null)

onMounted(() => {
  emojiRef.value.appendChild(emojiElement.value)
})

watch(emojiElement, (current, previous) => {
  emojiRef.value.replaceChild(current, previous)
})
</script>

<style scoped lang="sass">
.emoji
  ::v-deep(img.emoji)
    width: 1.2em
    height: 1.2em
    margin: .1em .1em
    vertical-align: middle
</style>
