<template>
  <MaybeMentionable
    :disable="!mentions"
    @apply="onApplyMentions"
  >
    <div class="relative-position q-mb-sm">
      <QInput
        ref="input"
        v-bind="$attrs"
        :model-value="modelValue"
        type="textarea"
        input-class="overscroll-contain"
        :input-style="{ minHeight: '100px', ...$attrs['input-style'] }"
        autogrow
        bottom-slots
        hide-hint
        @keydown.b.ctrl.prevent="applyMarkup($event, '**')"
        @keydown.i.ctrl.prevent="applyMarkup($event, '_')"
        @keydown.5.alt.ctrl="applyMarkup($event,'~~')"
        @update:model-value="newValue => $emit('update:modelValue', newValue)"
      >
        <template
          v-if="icon"
          #before
        >
          <QIcon :name="icon" />
        </template>
        <template #hint>
          <div
            class="row markdown-helper"
          >
            <span class="text-bold">**{{ t('MARKDOWN_INPUT.BOLD') }}**</span>
            <span class="text-italic">_{{ t('MARKDOWN_INPUT.ITALIC') }}_</span>
            <span>~~<s>{{ t('MARKDOWN_INPUT.STRIKE') }}</s>~~</span>
            <span>&gt;{{ t('MARKDOWN_INPUT.QUOTE') }}</span>
            <a
              href="https://guides.github.com/features/mastering-markdown/"
              target="_blank"
              rel="nofollow noopener noreferrer"
              :title="t('MARKDOWN_INPUT.HELP')"
            >
              <QIcon name="fas fa-question-circle" />
            </a>
          </div>
        </template>
        <template
          v-for="(_, slotName) in $slots"
          #[slotName]="slotData"
        >
          <slot
            :name="slotName"
            v-bind="slotData || {}"
          />
        </template>
      </QInput>
      <QBtn
        v-if="modelValue"
        :label="t('BUTTON.PREVIEW')"
        size="xs"
        color="primary"
        outline
        class="absolute-bottom-right bg-white"
        style="bottom: -10px"
        @click="show = true"
      >
        <QDialog v-model="show">
          <QCard class="markdown-input-preview-card">
            <QCardSection>
              <div class="text-h6">
                {{ t('BUTTON.PREVIEW') }}
              </div>
            </QCardSection>
            <QCardSection>
              <Markdown
                v-if="modelValue"
                :source="modelValue"
                :mentions="mentions"
              />
            </QCardSection>
            <QCardActions align="right">
              <QBtn
                v-close-popup
                flat
                :label="t('BUTTON.CLOSE')"
                color="primary"
              />
            </QCardActions>
          </QCard>
        </QDialog>
      </QBtn>
    </div>
  </MaybeMentionable>
</template>

<script setup>
import {
  QInput,
  QIcon,
  QBtn,
  QDialog,
  QCard,
  QCardSection,
  QCardActions,
} from 'quasar'
import { ref, nextTick } from 'vue'
import { useI18n } from 'vue-i18n'

import Markdown from '@/utils/components/Markdown.vue'
import MaybeMentionable from '@/utils/components/MaybeMentionable.vue'

import { addMarkup, removeMarkup, hasMarkup } from '../markdownUtils'

defineOptions({
  inheritAttrs: false,
})

defineProps({
  modelValue: {
    default: '',
    type: String,
  },
  icon: {
    default: null,
    type: String,
  },
  mentions: {
    default: false,
    type: Boolean,
  },
})

const { t } = useI18n()

const emit = defineEmits(['update:modelValue'])

// reactive state
const input = ref(null)
const show = ref(false)

defineExpose({
  blur,
  focus,
})

// functions
function onApplyMentions () {
  // It loses focus when you click the mention menu without this
  if (!input.value) return
  requestAnimationFrame(() => {
    focus()
  })
}

function blur () {
  input.value.blur()
}

function focus () {
  input.value.focus()
}

// modifies input text by removing or adding markup
function applyMarkup (event, markup) {
  const { value: text, selectionStart, selectionEnd } = event.target

  let newText
  let startIndex

  if (hasMarkup(text, markup, selectionStart, selectionEnd)) {
    newText = removeMarkup(text, markup, selectionStart, selectionEnd)
    startIndex = selectionStart - markup.length
  }
  else {
    newText = addMarkup(text, markup, selectionStart, selectionEnd)
    startIndex = selectionStart + markup.length
  }

  emit('update:modelValue', newText)
  // preserves text user has selected
  nextTick(() => { event.target.setSelectionRange(startIndex, startIndex + selectionEnd - selectionStart) })
}

</script>

<style scoped lang="sass">
.markdown-helper
  padding-top: 6px
  font-size: .65rem

  > *
    padding-right: 10px

.markdown-input-preview-card
  min-width: 60%
  max-width: 700px
</style>
