<template>
  <div
    class="edit-box"
    :class="{ changed: hasChanged }"
  >
    <form
      class="q-gutter-y-lg"
      style="max-width: 700px"
      @submit.prevent="save"
    >
      <h3 v-if="isNew">
        {{ t('POLLS.CREATE') }}
      </h3>
      <h3 v-else>
        {{ t('POLLS.EDIT') }}
      </h3>

      <QInput
        v-model="edit.title"
        :label="t('LABELS.TITLE')"
        :error="Boolean(errors.title)"
        :error-message="errors.title"
        filled
        autofocus
      />

      <MarkdownInput
        v-model="edit.description"
        :label="t('LABELS.DESCRIPTION')"
        :error="Boolean(errors.description)"
        :error-message="errors.description"
        filled
      />

      <div class="text-h5">
        {{ t('LABELS.OPTIONS') }}
      </div>

      <div class="column gap-sm">
        <div
          v-for="(option, idx) in edit.options"
          :key="idx"
        >
          <QInput
            ref="optionRefs"
            v-model="option.title"
            :label="t('LABELS.OPTION')"
            :error="Boolean(errors.options?.[idx].title)"
            :error-message="errors.options?.[idx].title"
            filled
            hide-bottom-space
          >
            <template #prepend>
              <EmojiPicker
                padding="0"
                size="lg"
                :clearable="Boolean(option.emoji)"
                @select="(name) => option.emoji = name"
                @clear="() => option.emoji = null"
              >
                <template v-if="option.emoji">
                  <Emoji :name="option.emoji" />
                </template>
                <template v-else>
                  <i class="far fa-smile add-emoji-icon" />
                </template>
              </EmojiPicker>
            </template>
            <template
              v-if="edit.options.length > 1"
              #append
            >
              <QBtn
                size="sm"
                round
                class="q-icon"
                icon="fas fa-trash-alt"
                text-color="grey-6"
                @click="removeOption(option)"
              />
            </template>
          </QInput>
        </div>
      </div>

      <div>
        <QBtn
          icon="fas fa-plus"
          :label="t('POLLS.ADD_ANOTHER_OPTION')"
          @click="addOption()"
        />
      </div>

      <QField
        borderless
        hide-bottom-space
        dense
      >
        <QToggle
          v-model="allowMultipleAnswers"
          :label="t('POLLS.ALLOW_MULTIPLE_ANSWERS')"
        />
      </QField>

      <QField
        borderless
        hide-bottom-space
        dense
      >
        <QToggle
          v-model="edit.anonymous"
          :label="t('POLLS.ANONYMOUS_VOTING')"
        />
      </QField>

      <div class="row justify-end q-gutter-sm q-mt-sm">
        <QBtn
          type="button"
          @click="emit('cancel')"
        >
          {{ t('BUTTON.CANCEL') }}
        </QBtn>

        <QBtn
          type="submit"
          color="primary"
          :disable="!canSave"
          :loading="isPending"
        >
          {{ t(isNew ? 'BUTTON.CREATE' : 'BUTTON.SAVE_CHANGES') }}
        </QBtn>
      </div>
    </form>
  </div>
</template>

<script setup>
import { helpers } from '@vuelidate/validators'
import { QInput, QBtn, QField, QToggle } from 'quasar'
import { computed, nextTick, ref, toRef } from 'vue'
import { useI18n } from 'vue-i18n'

import { useForm } from '@/utils/forms'
import { required } from '@/utils/validation'

import Emoji from '@/messages/components/Emoji.vue'
import EmojiPicker from '@/messages/components/EmojiPicker.vue'
import MarkdownInput from '@/utils/components/MarkdownInput.vue'

const { t } = useI18n()

const emit = defineEmits([
  'ok',
  'cancel',
])

const props = defineProps({
  poll: {
    type: Object,
    required: true,
  },
  create: {
    type: Function,
    required: true,
  },
  createStatus: {
    type: Object,
    required: true,
  },
})

const optionRefs = ref()

const poll = toRef(props, 'poll')
const createStatus = toRef(props, 'createStatus')

const {
  isNew,
  hasChanged,
  isPending,
  canSave,
  errors,
  edit,
  save,
} = useForm(poll, {
  rules: {
    title: {
      required,
    },
    description: {},
    options: {
      $each: helpers.forEach({
        title: {
          required,
        },
      }),
    },
    anonymous: {
      required,
    },
    minVoteOptions: {
      required,
    },
    maxVoteOptions: {
      required,
    },
  },
  create: props.create,
  createStatus,
  // No updating for polls?
  // update,
  // updateStatus,
  update () {},
  updateStatus () {},
  confirm: true,
  onSuccess () {
    emit('ok')
  },
})

function addOption () {
  edit.value.options.push({
    title: '',
  })
  nextTick(() => {
    const lastOptionRef = optionRefs.value[optionRefs.value.length - 1]
    if (lastOptionRef) {
      lastOptionRef.focus()
    }
  })
}

function removeOption (option) {
  const idx = edit.value.options.indexOf(option)
  if (idx !== -1) {
    edit.value.options.splice(idx, 1)
  }
}

// Make a simple boolean we can get/set instead of setting the min/max values
const allowMultipleAnswers = computed({
  get () {
    return edit.value.maxVoteOptions === -1 || edit.value.maxVoteOptions > 1
  },
  set (val) {
    if (val) {
      edit.value.minVoteOptions = 1
      edit.value.maxVoteOptions = -1
    }
    else {
      edit.value.minVoteOptions = 1
      edit.value.maxVoteOptions = 1
    }
  },
})

</script>

<style scoped lang="sass">
@import 'editbox'

.add-emoji-icon
  width: 1.2em
  height: 1.2em
  margin: .1em .1em
  vertical-align: middle

</style>
