import { usePollItemQuery } from '@/polls/queries'
import { useIntegerRouteParam } from '@/utils/composables'
import { defineService } from '@/utils/datastore/helpers'

export const useActivePollService = defineService(() => {
  const pollId = useIntegerRouteParam('pollId')

  const {
    poll,
    isLoading,
  } = usePollItemQuery({ pollId })

  return {
    pollId,
    poll,
    isLoading,
  }
})
