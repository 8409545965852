<template>
  <QBtn v-bind="$attrs">
    <slot>
      <span :style="{ opacity }">
        <i class="far fa-smile" />&nbsp;+
      </span>
    </slot>
    <QMenu
      anchor="top left"
      self="top left"
    >
      <div style="width: 195px">
        <EmojiPickerInner
          :clearable="clearable"
          :exclude="exclude"
          @select="(...args) => emit('select', ...args)"
          @clear="emit('clear')"
        />
      </div>
    </QMenu>
  </QBtn>
</template>

<script setup>
import {
  QBtn,
  QMenu,
} from 'quasar'
import { defineAsyncComponent } from 'vue'

const EmojiPickerInner = defineAsyncComponent(() => import('./EmojiPickerInner.vue'))

defineProps({
  opacity: {
    type: Number,
    default: 1,
  },
  exclude: {
    type: Array,
    default: () => [],
  },
  clearable: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits([
  'select',
  'clear',
])
</script>
