import { useQuery, useQueryClient } from '@tanstack/vue-query'
import { computed, unref } from 'vue'

import { useSocketEvents } from '@/utils/composables'
import { useQueryHelpers } from '@/utils/queryHelpers'

import api from './api/users'

export const QUERY_KEY_BASE = 'users'
export const queryKeyUserListAll = () => [QUERY_KEY_BASE, 'list', 'all'].filter(Boolean)
export const queryKeyUserProfile = userId => [QUERY_KEY_BASE, 'profile', userId].filter(Boolean)

/**
 * Handler for socket updates
 */
export function useUsersUpdater () {
  const queryClient = useQueryClient()
  const { on } = useSocketEvents()
  const { updateOrInvalidateListEntry } = useQueryHelpers()

  on(
    [
      'auth:user',
      'users:user',
    ],
    async user => {
      await queryClient.invalidateQueries({ queryKey: queryKeyUserProfile(user.id) })
      updateOrInvalidateListEntry(queryKeyUserListAll(), user)
    },
  )
  on(
    [
      'groups:user_joined',
      'groups:user_left',
    ],
    async ({ userId }) => {
      await queryClient.invalidateQueries({ queryKey: queryKeyUserProfile(userId) })
      await queryClient.invalidateQueries({ queryKey: queryKeyUserListAll() })
    },
  )
}

/**
 * Holds all users across all groups
 */
export function useUserListAllQuery (queryOptions = {}) {
  const query = useQuery({
    queryKey: queryKeyUserListAll(),
    queryFn: () => api.list(),
    staleTime: 15 * 60 * 1000, // rely on websockets to keep updated, but refresh after 15 minutes
    placeholderData: () => [],
    ...queryOptions,
  })
  return {
    ...query,
    users: query.data,
  }
}

export function useUserProfileQuery ({ userId }) {
  const query = useQuery({
    queryKey: queryKeyUserProfile(userId),
    queryFn: () => {
      try {
        return api.getProfile(unref(userId))
      }
      catch (error) {
        return api.getInfo(unref(userId))
      }
    },
    enabled: computed(() => Boolean(unref(userId))),
  })
  return {
    ...query,
    user: query.data,
  }
}
